import Swiper, {Navigation, Autoplay, EffectCreative} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const statsTextImageSlider = document.getElementsByClassName(
  'stats-section__text-image-slider'
)[0];

if (statsTextImageSlider) {
  // const specialisedSlidesArr =
  //   specialisedSlider.querySelectorAll('.swiper-slide');
  // let loop = false;
  // let loopedSlides = false;
  //
  // if (specialisedSlidesArr.length >= 3) {
  //   loop = true;
  //   loopedSlides = specialisedSlidesArr.length * 4;
  // }

  const statsTextImageSwiper = new Swiper('.stats-section__text-image-slider', {
    modules: [Navigation, Autoplay, EffectCreative],
    slidesPerView: 1,
    spaceBetween: 20,
    threshold: 10,
    // loop: loop,
    // loopedSlides: loopedSlides,
    autoHeight: true,
    autoplay: false,
    navigation: {
      prevEl: '.stats-section__text-image-slider .slider-buttons .slider-btn.prev',
      nextEl: '.stats-section__text-image-slider .slider-buttons .slider-btn.next',
    },
    effect: 'creative',
    creativeEffect: {
      limitProgress: 3,
      prev: {
        translate: [0, 0, -320],
        opacity: 0,
        effect: 'scale',
        scale: 0.6,
      },
      next: {
        /*translate: ['calc(100% - 20px)', 0, 0],*/
        translate: ['calc(100% + 20px)', 0, 0],
        opacity: 0,
        effect: 'scale',
        /*scale: 0.79,*/
      },
    },
    breakpoints: {
      640: {
        autoHeight: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
      }
    },
  });

  const statsTextImageTabs = document.querySelectorAll('.work-for-us-section__benefit');

  statsTextImageTabs.forEach((tab, index) => {
    tab.addEventListener('click', () => {

      for (let i = 0; i < statsTextImageTabs.length; i++) {
        statsTextImageTabs[i].classList.remove('active');
      }

      statsTextImageSwiper.slideTo(index);
      tab.classList.add('active');
    });
  });

  /*swiperObserver(statsTextImageSwiper);*/
}
