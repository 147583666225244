import './utils/vh.utils.js';
import './utils/safari.utils.js';
/*import './libs/sidebar.utils.js';*/
import './utils/animate-on-view.utils.js';
import './utils/accordion.utills.js';

import './components/header.component.js';
/*import './components/uploadFile.component.js';*/
import './components/inputs.component.js';

/*Sliders*/
import './components/specialized.component.js';
import './components/our-history.component.js';
import './components/testimonials.component.js';
import './components/jobs.component.js';
import './components/blogs.component.js';
import './components/stats-text-image.component.js';
/*import './components/global-opportunities.component.js';*/

/*Tabs*/
import './components/services.component.js';

/*Sidebar*/
import './components/jobs-search.component.js';

import './components/sectors.component.js';

import './libs/countUp.lib.js';

/*Other*/
import './components/teams.component.js';

/*document.querySelector('body').style.background = 'red';*/
