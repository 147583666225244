import { followingSidebar } from '../utils/sidebar.utils.js';
import { log } from 'sharp/lib/libvips.js';

const sidebar = document.querySelector('#sidebar');
const contentSticky = document.querySelector('#content-sticky');
const filtersBtn = document.querySelector('.filters-btn');
const contentResult = document.querySelector('.job-search-section__result');

if (sidebar && window.innerWidth >= 640) {
  followingSidebar({ sidebar, contentSticky });
}

if (filtersBtn) {
  filtersBtn.addEventListener('click', () => {
    filtersBtn.classList.toggle('active');
    contentSticky.classList.toggle('active');
    contentResult.classList.toggle('active');
  });

  contentResult.addEventListener('click', () => {
    if (contentResult.classList.contains('active')) {
      filtersBtn.classList.remove('active');
      contentSticky.classList.remove('active');
      contentResult.classList.remove('active');
    }
  });
}

window.jobsFilters = function jobsFilters() {
  if (document.getElementsByClassName('filters-main-parent')[0]) {
    const filtersMainParent = document.querySelector('.filters-main-parent');
    const filtersItemsArr = filtersMainParent.querySelectorAll('li');

    filtersMainParent.style.opacity = '1';

    filtersItemsArr.forEach((item, i) => {
      const btn = item.querySelector('.text-checkbox__icon');
      const childrenList = item.querySelectorAll('ul');
      const parentEl = btn.parentElement;
      const checkbox = parentEl.querySelector('input[type="checkbox"]');
      const vacanciesIndexesArr = checkbox.getAttribute('data-ids');

      let childrenListHeight = 0;

      if (childrenList.length) {
        childrenList.forEach((listHeight) => {
          childrenListHeight = childrenListHeight + listHeight.clientHeight;
        });
      }

      btn.style.opacity = '1';

      const parentLi = btn.parentElement.parentElement;
      const ulElements = parentLi.querySelectorAll('ul');
      const firstLevelUlElements = [];
      if (ulElements.length) {
        ulElements.forEach((ul) => {
          if (ul.parentElement === parentLi) {
            firstLevelUlElements.push(ul);
          }
        });
      }
      /*console.log(checkbox);
      console.log(firstLevelUlElements);*/

      const countText = checkbox.parentElement.querySelector(
        '.text-checkbox__content .count-jobs'
      );
      const countInitTextContent = countText.getAttribute('data-init-count');

      let checkedCount = 0;

      checkbox.addEventListener('click', () => {
        let parentUl =  checkbox.parentElement.parentElement.parentElement.parentElement;
        if(checkbox.parentElement.classList.contains('parent-checkbox')) {
          parentUl =
              checkbox.parentElement.parentElement;
        }
        
        const currentIds = JSON.parse(checkbox.getAttribute('data-ids'));

        let commonIds = [];
        let checkedIds = '';

        if(checkbox.parentElement.classList.contains('parent-checkbox')) {
          btn.classList.add('active');
          parentLi.classList.add('active');

          if (ulElements.length) {
            checkUlStatus();
          }
        }

        /*const checkedCheckboxes = parentUl.querySelectorAll(
          'input[type="checkbox"]:checked'
        );
        for (let j = 0; j < checkedCheckboxes.length; j++) {
          const vacanciesIds = checkedCheckboxes[j].getAttribute('data-ids');
          console.log(vacanciesIds);
        }
        console.log(checkedCheckboxes);*/

        const adjacentCheckboxes = parentUl.querySelectorAll('input');
        adjacentCheckboxes.forEach((adjacentCheckbox) => {
          /*console.log(adjacentCheckbox, adjacentCheckbox.checked);*/
          if (adjacentCheckbox.checked) {
            const idsStr = adjacentCheckbox.getAttribute('data-ids');
            checkedIds += idsStr;
          }
        });

        const regex = /\d+/g;
        const idsArr = [];

        let match;
        while ((match = regex.exec(checkedIds))) {
          idsArr.push(parseInt(match[0], 10));
        }

        const uniqueIdsArr = [...new Set(idsArr)];


        /*console.log(checkedIds);
        console.log(uniqueIdsArr);*/

        commonIds = uniqueIdsArr;

        /*console.log(commonIds);*/

        /*parentUl.setAttribute('data-ids', `${commonIds}`);*/

        checkedCount = commonIds.length;
        /*console.log(checkedCount);*/

        const parents = $(checkbox).parents('li');
        parents.each((idx, el) => {
          /*console.log(el, idx);*/

          if (idx !== 0) {
            const elCountText = el.querySelector(
              '.text-checkbox__content .count-jobs'
            );

            if (checkedCount === 0) {
              elCountText.textContent =
                elCountText.getAttribute('data-init-count');
              /*console.log(elCountText.getAttribute('data-init-count'));*/
            } else {
              elCountText.textContent = checkedCount;
            }
          }
        });

        if (
          checkbox.parentElement.nextElementSibling &&
          checkbox.parentElement.nextElementSibling.tagName === 'UL'
        ) {
          if (checkbox.checked) {
            checkbox.nextElementSibling.nextElementSibling.querySelector(
              '.count-jobs'
            ).textContent = checkbox.nextElementSibling.nextElementSibling
              .querySelector('.count-jobs')
              .getAttribute('data-init-count');
          } else {
            checkbox.nextElementSibling.nextElementSibling.querySelector(
              '.count-jobs'
            ).textContent = checkedCount;
            /*console.log(parentUl)*/
          }
        }

        if(countText.textContent === "0") {
          countText.textContent =
              countText.getAttribute('data-init-count');
        }
      });

      function checkUlStatus() {
        if (parentLi.classList.contains('active')) {
          for (let j = 0; j < firstLevelUlElements.length; j++) {
            firstLevelUlElements[j].style.maxHeight = `${childrenListHeight}px`;
            firstLevelUlElements[j].style.minHeight = `40px`;
            firstLevelUlElements[j].style.opacity = '1';
            firstLevelUlElements[j].classList.add('is-open');
          }
        } else {
          for (let j = 0; j < firstLevelUlElements.length; j++) {
            firstLevelUlElements[j].style.maxHeight = '0';
            firstLevelUlElements[j].style.minHeight = `0`;
            firstLevelUlElements[j].style.opacity = '0';
            firstLevelUlElements[j].classList.remove('is-open');
          }
        }
      }
      checkUlStatus();

      /*function extractValueInParentheses(text) {
        const regex = /\((\d+)\)/;
        const matches = text.match(regex);

        if (matches && matches.length > 1) {
          return parseInt(matches[1], 10);
        } else {
          return null;
        }
      }*/

      if (!item.querySelector('ul')) {
        btn.classList.add('is-hidden');
      }

      btn.addEventListener('click', (e) => {
        e.preventDefault();
        btn.classList.toggle('active');
        parentLi.classList.toggle('active');
        if (ulElements.length) {
          checkUlStatus();
        }
      });


    });
  }
};
jobsFilters();

window.scrollToJobsResult = function scrollToJobsResult(el) {
  if (window.innerWidth >= 640) {
    scrollToEl(el);
  }
};
