function teamsCardsFunc() {
  if (document.getElementsByClassName('team-card')[0]) {
    const teamCardsArr = document.querySelectorAll('.team-card__image');
    teamCardsArr.forEach((card) => {
      card.addEventListener('click', () => {
        card.classList.add('is-active');
      });
    });
  }
}
teamsCardsFunc();
