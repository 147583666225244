import Swiper, { Navigation, Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const specialisedSlider = document.getElementsByClassName(
  'specialised-section__slider'
)[0];

if (specialisedSlider) {
  // const specialisedSlidesArr =
  //   specialisedSlider.querySelectorAll('.swiper-slide');
  // let loop = false;
  // let loopedSlides = false;
  //
  // if (specialisedSlidesArr.length >= 3) {
  //   loop = true;
  //   loopedSlides = specialisedSlidesArr.length * 4;
  // }

  const specialisedSwiper = new Swiper('.specialised-section__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 20,
    threshold: 10,
    // loop: loop,
    // loopedSlides: loopedSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.specialised-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.specialised-slider-buttons.slider-buttons .slider-btn.next',
    },
    /*effect: 'creative',
    creativeEffect: {
      limitProgress: 3,
      prev: {
        translate: [0, 0, -320],
        opacity: 0,
      },
      next: {
        /!*translate: ['calc(100% - 20px)', 0, 0],*!/
        translate: ['calc(100% + 20px)', 0, 0],
        opacity: 1,
        effect: 'scale',
        /!*scale: 0.79,*!/
      },
    },*/
  });

  swiperObserver(specialisedSwiper);
}
