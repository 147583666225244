import Swiper, { Navigation, EffectCreative, Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const testimonialsSlider = document.getElementsByClassName(
  'testimonials-section__slider'
)[0];

if (testimonialsSlider) {
  const slidesArr = testimonialsSlider.querySelectorAll('.swiper-slide');

  /*let loop = false;
  let loopedSlides = false;

  if (slidesArr.length >= 3) {
    loop = true;
    loopedSlides = slidesArr.length * 4;
  }*/

  const testimonialsSwiper = new Swiper('.testimonials-section__slider', {
    modules: [Navigation, EffectCreative, Autoplay],
    slidesPerView: 1,
    spaceBetween: 20,
    threshold: 10,
    /*loop: loop,
    loopedSlides: loopedSlides,*/
    /*autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },*/
    navigation: {
      prevEl: '.testimonials-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.testimonials-slider-buttons.slider-buttons .slider-btn.next',
    },
    effect: 'creative',
    creativeEffect: {
      limitProgress: 3,
      prev: {
        translate: [0, 0, -320],
        opacity: 0,
      },
      next: {
        /*translate: ['calc(100% - 20px)', 0, 0],*/
        translate: ['calc(100% + 20px)', 0, 0],
        opacity: 1,
        effect: 'scale',
        /*scale: 0.79,*/
      },
    },
    on: {
      afterInit: function (swiper) {
        console.log(swiper)
        setTimeout(function () {
          const testimonialsSlidesArr =
              swiper.slides;

          testimonialsSlidesArr.forEach((item) => {
            const textContent = item.querySelector('.testimonials-section__item-text');
            const textScrollContent = item.querySelector('.testimonials-section__item-scroll');
            const btn = item.querySelector('.testimonials-section__item-btn');

            const characterCount = $(textScrollContent).text().length;

            console.log($(textScrollContent).text().length);

            const initialHeight = textContent.clientHeight
            /*console.log('test22', initialHeight)*/
            /*textContent.style.overflow = 'hidden';*/
            textContent.style.maxHeight = `${138}px`

            if (textContent.clientHeight >= 138 && characterCount > 194) {
              btn.style.display = 'flex';
            }

            btn.addEventListener('click', () => {
              btn.classList.toggle('active');

              if(btn.classList.contains('active')) {
                textContent.style.maxHeight = `600px`
                textContent.classList.toggle('active');
                setTimeout(function () {
                  textScrollContent.querySelector('.simplebar-track.simplebar-vertical').style.opacity = '1';
                }, 300);
              } else {
                textContent.style.maxHeight = `${initialHeight}px`
                textScrollContent.querySelector('.simplebar-track.simplebar-vertical').style.opacity = '0';
                setTimeout(function () {
                  textContent.classList.toggle('active');
                }, 400)
              }
            });

            const scrollSimplebar = new SimpleBar(textScrollContent);
            textScrollContent.querySelector('.simplebar-track.simplebar-vertical').style.opacity = '0';
          });
        }, 300);
      },
    },
  });

  /*swiperObserver(testimonialsSwiper);*/
}
