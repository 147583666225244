import Swiper, { Navigation, Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const ourHistorySlider = document.getElementsByClassName(
  'our-history-section__slider'
)[0];

if (ourHistorySlider) {
  const ourHistorySlidesArr =
    ourHistorySlider.querySelectorAll('.swiper-slide');

  ourHistorySlidesArr.forEach((slide) => {
    const textContainer = slide.querySelector(
      '.our-history-section__item-content'
    );
    const text = textContainer.querySelector('.our-history-section__item-text');
    const textClone = text.cloneNode(true);
    textClone.classList.add('our-history-section__item-text--second');
    textContainer.appendChild(textClone);
  });

  // let loop = false;
  // let loopedSlides = false;
  //
  // if (specialisedSlidesArr.length >= 3) {
  //   loop = true;
  //   loopedSlides = specialisedSlidesArr.length * 4;
  // }

  const ourHistorySwiper = new Swiper('.our-history-section__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 16,
    threshold: 10,
    // loop: loop,
    // loopedSlides: loopedSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.history-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.history-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      651: {
        spaceBetween: 18,
        slidesPerView: 1,
      },
      901: {
        spaceBetween: 20,
        slidesPerView: 1,
      },
    },
  });

  swiperObserver(ourHistorySwiper);
}
