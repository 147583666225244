import Swiper, { Navigation, Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

window.jobSliderFunc = function jobsSliderFunc() {
  const jobsSlider = document.getElementsByClassName('jobs-slider')[0];

  if (jobsSlider) {
    const ourHistorySlidesArr = jobsSlider.querySelectorAll('.swiper-slide');

    // let loop = false;
    // let loopedSlides = false;
    //
    // if (specialisedSlidesArr.length >= 3) {
    //   loop = true;
    //   loopedSlides = specialisedSlidesArr.length * 4;
    // }

    const jobsSwiper = new Swiper('.jobs-slider', {
      modules: [Navigation, Autoplay],
      slidesPerView: 1,
      spaceBetween: 12,
      threshold: 10,
      // loop: loop,
      // loopedSlides: loopedSlides,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: '.jobs-slider-buttons.slider-buttons .slider-btn.prev',
        nextEl: '.jobs-slider-buttons.slider-buttons .slider-btn.next',
      },
      breakpoints: {
        690: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
      },
    });

    swiperObserver(jobsSwiper);
  }
};
jobSliderFunc();
