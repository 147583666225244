import { globalData } from './inputs.component.js';

if (document.getElementsByClassName('select-sectors-section__btn')[0]) {
  const selectSectorsBtnArr = document.querySelectorAll(
    '.select-sectors-section__btn'
  );
  selectSectorsBtnArr.forEach((btn, i) => {
    btn.addEventListener('click', () => {
      const id = btn.getAttribute('data-sector-id');
      console.log(id);
      const choicesArr = globalData.choicesAllArr;
      choicesArr.forEach((choices) => {
        choices.setChoiceByValue(id);
      });
    });
  });
}
