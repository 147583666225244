export const globalData = {
  choicesAllArr: [],
};

window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

const choicesDOM = document.querySelector(['[data-choices]']);
if (choicesDOM) {
  const choicesArr = document.querySelectorAll(['[data-choices]']);
  for (let i = 0; i < choicesArr.length; i++) {
    const parentContainer = choicesArr[i].parentNode;
    /*const list = parentContainer.querySelector(".default-select__list");*/

    const choices = new Choices(choicesArr[i], {
      searchEnabled: false,
      itemSelectText: '',
      placeholder: true,
      searchPlaceholderValue: 'Search',
      classNames: {
        flippedState: '',
      },
    });
    globalData.choicesAllArr.push(choices);

    const choicesMultipleElement = parentContainer.querySelector(
      ".choices[data-type='select-multiple']"
    );

    if (choicesMultipleElement) {
      choicesMultipleElement.addEventListener('click', () => {
        if (parentContainer.querySelector('.is-open')) {
          choices.hideDropdown();
        }
      });
    }

    /* New multiselect logic */
    if (
      choicesArr[i].multiple &&
      choicesArr[i].hasAttribute('data-multiple-list-logic')
    ) {
      let optionName = null;
      let optionValue = null;

      const multiplePlaceholder = parentContainer.querySelector(
        '.choices__list--multiple'
      );

      const list = document.createElement('ul');
      list.className = 'meta-select__list';
      parentContainer.appendChild(list);

      function createListItem(optionName, optionValue) {
        const listItem = document.createElement('li');
        listItem.setAttribute('data-val', optionValue);
        listItem.innerHTML = `${optionName}`;
        listItem.classList.add('meta-select__list-item');
        list.appendChild(listItem);

        listItem.addEventListener('click', () => {
          handleListItemClick(listItem);
        });
      }
      function handleSelectedOptions() {
        list.innerHTML = '';

        const selectedOptions = Array.from(choicesArr[i].selectedOptions);

        if (selectedOptions.length >= 1) {
          list.classList.add('is-visible');
        } else {
          list.classList.remove('is-visible');
        }

        if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
          choices.setChoiceByValue('');
        }

        selectedOptions.forEach(function (option) {
          optionName = option.textContent;
          optionValue = option.value;
          if (optionName !== 'Select') {
            createListItem(optionName, optionValue);
          }
        });

        const listArr = list.querySelectorAll('.meta-select__list-item');
        if (listArr.length === 1) {
          multiplePlaceholder.textContent = optionName;
        } else if (listArr.length >= 2) {
          multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
        } else {
          multiplePlaceholder.textContent = 'Select';
        }
      }

      function handleListItemClick(listItem) {
        const optionValue = listItem.getAttribute('data-val');

        choices.removeActiveItemsByValue(optionValue);
        handleSelectedOptions();
      }

      handleSelectedOptions();

      choicesArr[i].addEventListener('change', function () {
        handleSelectedOptions();
      });

      list.addEventListener('click', function (event) {
        const liElement = event.target.closest('.meta-select__list-item');
        if (liElement) {
          handleListItemClick(liElement);
        }
      });
    }
  }
}

window.phoneInputsFunc = function phoneInputsFunc() {
  const telInputs = document.querySelectorAll('input[type="tel"]');

  function handleTelInput(event) {
    const allowedCharacters = [
      '(',
      ')',
      '-',
      '+',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
    ];
    const input = event.target;
    const inputValue = input.value;
    const sanitizedValue = [];

    for (let i = 0; i < inputValue.length; i++) {
      const char = inputValue[i];
      if (allowedCharacters.includes(char)) {
        sanitizedValue.push(char);
      }
    }

    input.value = sanitizedValue.join('');
  }

  if (telInputs.length > 0) {
    telInputs.forEach((input) => {
      input.addEventListener('input', handleTelInput);
    });
  }
};

phoneInputsFunc();
