import { CountUp } from 'countup.js';

/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 * **/
/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 * **/
const counts = Array.from(document.getElementsByClassName('count-up'));
const countUpArr = [];
if (counts) {
  const defaultOptions = {
    // separator: "",
    enableScrollSpy: false,
    scrollSpyRunOnce: true,
  };

  let idNumber = 1;

  counts.forEach((count) => {
    const id = `count-up-${idNumber}`,
        value = parseFloat(count.innerHTML);

    let optionsFromDataAttr = { ...count.dataset };

    for (const key in optionsFromDataAttr) {
      if (optionsFromDataAttr[key] === '') {
        optionsFromDataAttr[key] = true;
      }
    }

    count.id = id;
    countUpArr.push(
        new CountUp(
            id,
            value,
            Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
        )
    );
    idNumber++;
  });
}

const scrollEvents = () => {
  const trueScrollTarget = document.getElementsByClassName('scroll-target')[0];

  if (trueScrollTarget) {
    const scrollTarget = document.querySelectorAll('.scroll-target');

    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry, i) => {
            if (entry.isIntersecting) {
              const elem = entry.target;
              countUpArr.forEach((countUp) => {
                if (countUp.el.id === elem.querySelector('.count-up').id) {
                  countUp.start();
                }
              });
            }
          });
        },
        {
          threshold: 0,
          rootMargin: '0% 0% -10% 0%',
        }
    );

    scrollTarget.forEach((target, index) => {
      observer.observe(target);
    });
  }
};

scrollEvents();
