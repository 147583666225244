const servicesSection = document.getElementsByClassName('services-section')[0];

if (servicesSection) {
  const servicesItemsArr = servicesSection.querySelectorAll(
    '.services-section__tabs-item'
  );
  const servicesImagesArr = servicesSection.querySelectorAll(
    '.services-section__image'
  );
  const servicesButtonsArr = servicesSection.querySelectorAll(
    '.services-section__tabs-btn'
  );

  servicesButtonsArr.forEach((btn, i) => {
    btn.addEventListener('click', () => {
      for (let j = 0; j < servicesItemsArr.length; j++) {
        if (servicesItemsArr[j].classList.contains('active')) {
          servicesItemsArr[j].classList.remove('active');
        }
      }
      for (let j = 0; j < servicesButtonsArr.length; j++) {
        if (servicesButtonsArr[j].classList.contains('active')) {
          servicesButtonsArr[j].classList.remove('active');
        }
      }
      for (let j = 0; j < servicesImagesArr.length; j++) {
        if (servicesImagesArr[j].classList.contains('active')) {
          servicesImagesArr[j].classList.remove('active');
        }
      }

      btn.classList.add('active');
      servicesItemsArr[i].classList.add('active');
      servicesImagesArr[i].classList.add('active');
    });
  });
}
