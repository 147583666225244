import Swiper, { Navigation, Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const blogsSlider = document.getElementsByClassName(
  'similar-blogs-section__slider'
)[0];

if (blogsSlider) {
  const ourHistorySlidesArr = blogsSlider.querySelectorAll('.swiper-slide');

  // let loop = false;
  // let loopedSlides = false;
  //
  // if (specialisedSlidesArr.length >= 3) {
  //   loop = true;
  //   loopedSlides = specialisedSlidesArr.length * 4;
  // }

  const blogsSwiper = new Swiper('.similar-blogs-section__slider', {
    modules: [Navigation, Autoplay],
    slidesPerView: 1,
    spaceBetween: 20,
    threshold: 10,
    // loop: loop,
    // loopedSlides: loopedSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.blogs-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.blogs-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      590: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      901: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
    },
  });

  swiperObserver(blogsSwiper);
}
